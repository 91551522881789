import styled from "styled-components";
import { useState } from "react";

const SecondHeading = styled.h2`
  text-align: center;
  margin-top: 6rem;
  font-size: 2.4rem;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const Text = styled.p`
  text-align: center;
  margin-top: 2rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  line-height: 36px;
  text-align: justify;
  font-size: 1.1rem;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 0.9rem;
  }
  span {
    font-weight: 600;
  }
`;

const Map = styled.iframe`
  width: 100%;
  height: 400px;
  border: none;
  margin-top: 6rem;
`;

export const MainZalety = styled.div`
  padding: 2rem;
  width: 95%;
  margin: 4rem auto;
  background-color: #fff;
`;

export const ZaletyHeadingMain = styled.h2`
  text-align: center;
  margin-bottom: 4rem;
  margin-top: 4rem;
  font-size: 2rem;
  color: #333;
  font-weight: 400;
`;

export const ZaletyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
`;

export const Zaleta = styled.div`
  border: 1px solid #aaa;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

export const ZaletaIcon = styled.img`
  width: 50px;
  margin: 1rem auto;
`;

export const ZaletaHeading = styled.h3`
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #444;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ZaletaInfo = styled.p`
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;

  @media (max-width: 768px) {
    max-height: 50dvh;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 0;

  th,
  td {
    text-align: center;
    padding: 12px;
    border: 1px solid #ddd;
    font-size: 14px;
  }

  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  td a {
    color: #007bff;
    text-decoration: none;
  }

  td a:hover {
    text-decoration: underline;
  }

  .taken {
    color: #ff5e5e;
    font-weight: bold;
  }
`;

const StyledTh = styled.th`
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
`;

const GalleryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 80%;
  height: 65vh;
  margin: 4rem auto;

  @media (max-width: 768px) {
    width: 90%;
    height: 50vh;
    margin: 0rem auto;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 40vh;
  }
`;

const GalleryPhoto = styled.img`
  width: 80%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: auto;
  }
`;

const GalleryArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 22;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  ${(props) => (props.direction === "left" ? "left: 2%;" : "right: 2%;")}

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  @media (max-width: 480px) {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }
`;

const SpecyfikacjaContainer = styled.div`
  position: relative;
  padding: 1rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpecyfikacjaDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  background: rgb(42, 112, 212);
  background: linear-gradient(
    180deg,
    rgba(42, 112, 212, 1) 3%,
    rgba(110, 166, 247, 1) 49%
  );
  border-radius: 12px;
  padding: 1rem;
  -webkit-box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  -moz-box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  box-shadow: 9px 7px 17px -12px rgba(66, 68, 90, 1);
  width: 50%;
  height: 500px;
  overflow: auto;

  @media (max-width: 1599px) {
    width: 75%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 650px;
  }
`;

const SpecyfikacjaImage = styled.div`
  background-size: cover;
  background-position: center;
  flex: 1;
  border-radius: 6px;
  min-width: 320px;
  height: 420px;

  @media (max-width: 1024px) {
    height: 320px;
  }
`;

const SpecyfikacjaDescription = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  min-width: 320px;
  padding: 0.5rem;
  color: white;

  h4 {
    font-size: 1.3rem;
    text-align: center;
  }

  p {
    font-size: 0.9rem;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.left {
    left: 20%;

    @media (max-width: 1599px) {
      left: 5%;
    }

    @media (max-width: 1024px) {
      left: 0%;
      font-size: 1rem;
      width: 24px;
      height: 24px;
    }
  }

  &.right {
    right: 20%;

    @media (max-width: 1599px) {
      right: 5%;
    }

    @media (max-width: 1024px) {
      right: 0%;
      font-size: 1rem;
      width: 24px;
      height: 24px;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 75%;
  margin: 4rem auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:nth-child(1) {
    grid-column: span 2;
    grid-row: span 2;
  }
  &:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }
  &:nth-child(3) {
    grid-column: span 1;
    grid-row: span 1;
  }
  &:nth-child(4) {
    grid-column: span 2;
    grid-row: span 1;
  }
  &:nth-child(5) {
    grid-column: span 1;
    grid-row: span 1;
  }

  @media (max-width: 768px) {
    grid-column: span 1 !important;
    grid-row: span 1 !important;
  }
`;

const images = [
  "/polna/raz.jpeg",
  "/polna/dwa.jpeg",
  "/polna/trzy.jpeg",
  "/polna/cztery.jpeg",
  "/polna/piec.jpeg",
  "/polna/szesc.jpg",
];

const outsideImages = [
  "/polna/zewnatrz_polna.jpg",
  "/polna/zewnatrz_poln2.jpg",
];

const specyfikacjaData = [
  {
    photo: "/polna/zewnatrz_polna1.jpg",
    heading: "Fundamenty, ściany piwnic, kondygnacji nadziemnych",
    text: "Budynek niepodpiwniczony.Projektowane fundamenty – ławy fundamentowe żelbetowe (min C20/25, 60x40). Ściany fundamentowe żelbetowe, wylewane lub murowane z bloczków fundamentowych betonowych. Zbrojenie ław fundamentowych – 4 pręty fi12.",
  },
  {
    photo: "/polna/zewnatrz_polna2.jpg",
    heading: "Stropy, dach, wykończenie elewacji.",
    text: "Konstrukcja ścian nośnych zewnętrznych : Ceramika poryzowana gr. 24 cm lub beton komórkowy o grubości 24 cm, izolowane z zewnątrz styropianem typu EPS 032 Fasada Super (0,032 W/mK) wykończone tynkiem cienkowarstwowym na siatce. Konstrukcja ścian nośnych wewnętrznych Ceramika poryzowana / bloczek komórkowy gr. 24 cm z kruszywem ceramicznym gr. 18 cm, tynkowane. Ściany działowe Beton komórkowy gr. 12cm i 8 cm. Stropy między- kondygnacyjne łączące parter z górą żelbetowe prefabrykowane typu Teriva. Stropy oparte na ścianach zewnętrznych. Zbrojenie stropów wykonać wg. projektu wykonawczego konstrukcji. Nad kondygnacją +1 stropodach w układzie odwróconym z izolacją pw z membrany EPDM.",
  },
  {
    photo: "/polna/budowa_1.jpg",
    heading: "Stolarka Okienna",
    text: "Stolarka okienna zewnętrzna z profili PCV. Drzwi zewnętrzne wzmacniane, z dodatkową wkładką izolacyjną.",
  },
  {
    photo: "/polna/budowa_4.jpg",
    heading:
      "Wyposażenie instalacyjne, izolacje przeciwwilogciowe, termiczne i akustyczne.",
    text: "W projektowanym obiekcie przewiduje się realizację wentylacji mechanicznej wywiewnej Doprowadzenie wody do celów bytowych z wodociągu miejskiego z wykorzystaniem projektowanego przyłącza. Licznik zużycia ZWU w pomieszczeniach technicznych. Do czasu realizacji sieci kanalizacyjnej w drodze gminnej odprowadzenie ścieków do zbiorników bezodpływowych. Podłączenie do sieci elektrycznej NN złączem kablowym od skrzynki przyłączeniowej. Ogrzewanie budynku i przygotowanie c.w.u- powietrzna pompa ciepła typu split, jednostka wewnętrzna z zasobnikiem w pomieszczeniu technicznym na parterze. Ogrzewanie płaszczyznowe niskotemperaturowe",
  },
];

const Polna = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentOutsideIndex, setCurrentOutsideIndex] = useState(0);
  const [specyfikacjaIndex, setSpecyfikacjaIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleOutsidePrev = () => {
    setCurrentOutsideIndex((prevIndex) =>
      prevIndex === 0 ? outsideImages.length - 1 : prevIndex - 1
    );
  };

  const handleOutsideNext = () => {
    setCurrentOutsideIndex((prevIndex) =>
      prevIndex === outsideImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleSpecyfikacjaPrev = () => {
    setSpecyfikacjaIndex((prevIndex) =>
      prevIndex === 0 ? specyfikacjaData.length - 1 : prevIndex - 1
    );
  };

  const handleSpecyfikacjaNext = () => {
    setSpecyfikacjaIndex((prevIndex) =>
      prevIndex === specyfikacjaData.length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <>
      <SecondHeading>Osiedle Polna</SecondHeading>

      <GalleryContainer>
        <GalleryPhoto
          src={outsideImages[currentOutsideIndex]}
          alt={`Photo ${currentOutsideIndex + 1}`}
        />
      </GalleryContainer>

      <SecondHeading>Przykladowa Wizualizacja Wnętrza</SecondHeading>

      <GalleryContainer>
        <GalleryArrow direction="left" onClick={handlePrev}>
          &#8249;
        </GalleryArrow>
        <GalleryPhoto
          src={images[currentIndex]}
          alt={`Photo ${currentIndex + 1}`}
        />
        <GalleryArrow direction="right" onClick={handleNext}>
          &#8250;
        </GalleryArrow>
      </GalleryContainer>

      <Text>
        <span>Nasza inwestycja "Osiedle Polna"</span> położona jest w Osowcu
        gmina Żabia Wola w północno - zachodniej części gminy. Osiedle to
        znajduje się na przeciwko naszej poprzedniej inwestycji - "Osiedle
        Dworska". Osowiec ciągnie się od wschodu do zachodu przeważnie po jednej
        stronie drogi, wysadzonej dużymi topolami, które nadają bardzo duży urok
        wsi. Każdy budynek zapewnia na komfortowe zamieszkanie dwóch -cztero
        bądź piecioosobowej rodziny. Dzięki temu, że Osiedle położona jest wśród
        drzew można oderwać się od zgiełku miasta. Dodatkowo każdy lokal posiada
        około 500m2 działki. Od 2020r tworzymy i budujemy dla Was unikatowe,
        energooszczędne, nowoczesne projekty, które są tworzone z niesamowitą
        dbałością o szczegóły. Tworzymy firmę, której misją jest realizacja
        najlepszej oferty mieszkaniowej, a priorytetem dla nas jest satysfakcja
        i zadowolenie Klientów. Obecność specjalistów w naszej firmie pozwala
        nam wypracować bardzo wysokie standardy przy projektowaniu i budowie
        naszych inwestycji. Wykorzystujemy najnowsze rozwiązania technologiczne
        oraz staramy się wychodzić naprzeciw coraz to większym potrzebom naszych
        Klientów.
      </Text>

      <SecondHeading>Osiedle w Budowie</SecondHeading>

      <GridContainer>
        <GridImage src="/polna/zewnatrz_polna1.jpg" alt="Gallery Image 1" />
        <GridImage src="/polna/zewnatrz_polna2.jpg" alt="Gallery Image 1" />
        <GridImage src="/polna/budowa_1.jpg" alt="Gallery Image 1" />
      </GridContainer>

      <MainZalety>
        <ZaletyHeadingMain>Zalety</ZaletyHeadingMain>
        <ZaletyGrid>
          <Zaleta>
            <ZaletaHeading>Kameralne Osiedle</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/house.png" alt="House Icon" />
            <ZaletaInfo>
              Osiedle mieści 18 lokali mieszkalnych o powierzchni 89,46m2, każdy
              z działką około 500m2. Do każdego lokalu przynależne są 2 miejsca
              postojowe ułożone z kostki. Dzięki położeniu Osiedla wśród drzew
              można cieszyć się ciszą, spokojem i uciec od zgiełku miasta.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Komunikacja</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/train.png" alt="Train Icon" />
            <ZaletaInfo>
              15 minut pieszo do najbliższego przystanku autobusowego, z którego
              dostaniesz się do centrum Grodziska Mazowieckiego. 7 minut do
              trasy szybkiego ruchu z wygodnym dojazdem do centrum Warszawy lub
              Łodzi.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Relaks i sport</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/sports.png" alt="Sports Icon" />
            <ZaletaInfo>
              Dzięki temu, że Osowiec położony jest tylko 5 km od Grodziska
              Mazowieckiego gdzie oferowane są rozrywki na wysokim poziomie.
              Basen, kino, liczne restauracje, a także biblioteka to wszystko w
              zasięgu Twojej ręki.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Szkoły, przedszkola i żłobki</ZaletaHeading>
            <ZaletaIcon
              src="/nad_stawami/graduation-cap.png"
              alt="Graduation Icon"
            />
            <ZaletaInfo>
              Do najbliższej Szkoły podstawowej dzieli nas zaledwie 6 minut
              samochodem, żłobek 15 minut pieszo. Do Centrum Grodziska
              Mazowieckiego gdzie znajdują się liczne przedszkola dostaniesz się
              w ciągu 15 minut.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Kultura</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/masks.png" alt="Masks Icon" />
            <ZaletaInfo>
              W Żabiej Woli i najbliższych okolicach można zobaczyć ciekawe
              pałace oraz zabytkowe kościółki. Dwór w Żabiej Woli - dworek
              klasyczny wybudowany w XIXw Kościół Św. Apostołów Piotra i Pawła w
              Skułach - drewniana świątynia z przełomu XVII i XVIII wieku.
            </ZaletaInfo>
          </Zaleta>

          <Zaleta>
            <ZaletaHeading>Bliżej przyrody</ZaletaHeading>
            <ZaletaIcon src="/nad_stawami/nature.png" alt="Nature Icon" />
            <ZaletaInfo>
              Gmina Żabia Wola jest idealnym miejscem dla tych, którzy chcą
              odpocząć od nieustannego zgiełku stolicy w miejscu zacisznym,
              czystym i atrakcyjnych pod względem przyrodniczym. To taka
              ekologiczna ostoja, którą można przemierzać pieszo, na rowerach a
              nawet konno.
            </ZaletaInfo>
          </Zaleta>
        </ZaletyGrid>
      </MainZalety>

      <SecondHeading style={{ marginBottom: "4rem" }}>
        Specyfikacja
      </SecondHeading>

      <SpecyfikacjaContainer>
        <ArrowButton className="left" onClick={handleSpecyfikacjaPrev}>
          &lt;
        </ArrowButton>

        <SpecyfikacjaDiv>
          <SpecyfikacjaImage
            style={{
              backgroundImage: `url(${specyfikacjaData[specyfikacjaIndex]?.photo})`,
            }}
          />
          <SpecyfikacjaDescription>
            <h4>{specyfikacjaData[specyfikacjaIndex]?.heading}</h4>
            <p>{specyfikacjaData[specyfikacjaIndex]?.text}</p>
          </SpecyfikacjaDescription>
        </SpecyfikacjaDiv>

        <ArrowButton className="right" onClick={handleSpecyfikacjaNext}>
          &gt;
        </ArrowButton>
      </SpecyfikacjaContainer>

      <ZaletyHeadingMain>Dostępność Lokali</ZaletyHeadingMain>
      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <StyledTh id="nrLOK" scope="col">
                Nr Lok.
              </StyledTh>
              <StyledTh id="nrLOK" scope="col">
                Etap
              </StyledTh>
              <StyledTh id="powierzchniaLOK" scope="col">
                Powierzchnia m<sup>2</sup>
              </StyledTh>
              <StyledTh id="ogródLOK" scope="col">
                Ogród m<sup>2</sup>
              </StyledTh>
              <StyledTh id="ogródLOK" scope="col">
                Rzuty Techniczne
              </StyledTh>
              <StyledTh id="miejscaLOK" scope="col">
                Miejsca postojowe
              </StyledTh>
              <StyledTh id="statusLOK" scope="col">
                Status
              </StyledTh>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">1B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">2A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "yellow" }}>Rezerwacja</td>
            </tr>
            <tr>
              <th scope="row">2B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "yellow" }}>Rezerwacja</td>
            </tr>
            <tr>
              <th scope="row">3A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">3B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">1A</th>
              <td>2</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">1B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">2A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">2B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">3A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">3B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">1A</th>
              <td>3</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">1B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">2A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">2B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">3A</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
            <tr>
              <th scope="row">3B</th>
              <td>1</td>
              <td>
                89,46 m<sup>2</sup>
              </td>
              <td>
                500 m<sup>2</sup>
              </td>
              <td style={{ cursor: "pointer", fontWeight: "bold" }}>
                <a href="/polna/pdf_rzuty.pdf" target="_blank" rel="noreferer">
                  Otwórz PDF
                </a>
              </td>
              <td>2</td>
              <td style={{ color: "green" }}>wolny</td>
            </tr>
          </tbody>
        </StyledTable>
      </TableWrapper>

      <SecondHeading>Localizacja Osiedla</SecondHeading>

      <Text style={{ textAlign: "center" }}>
        <span>Szczęsne koło Grodziska Mazowieckiego</span>, wjazd z ulicy
        Łagodnej.
      </Text>

      <Map
        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2917.9171961254024!2d20.64183505171349!3d52.049393803918996!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2spl!4v1732566637695!5m2!1spl!2spl"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></Map>
    </>
  );
};

export default Polna;
